exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Social_social_lightbg__2Gwi5 {\n  transition: all 200ms linear;\n  padding: 3pt;\n  color: #1e1f26; }\n  .Social_social_lightbg__2Gwi5 svg {\n    transition: all 200ms linear; }\n  .Social_social_lightbg__2Gwi5:hover svg {\n    transform: scale(1.2); }\n  .Social_social_lightbg__2Gwi5:hover {\n    color: #af0d1a; }\n\n.Social_social_darkbg__1yVPf {\n  transition: all 200ms linear;\n  padding: 3pt;\n  color: #FFFFFF; }\n  .Social_social_darkbg__1yVPf svg {\n    transition: all 200ms linear; }\n  .Social_social_darkbg__1yVPf:hover svg {\n    transform: scale(1.2); }\n  .Social_social_darkbg__1yVPf:hover {\n    color: #ffdd40; }\n", ""]);

// exports
exports.locals = {
	"social_lightbg": "Social_social_lightbg__2Gwi5",
	"social_darkbg": "Social_social_darkbg__1yVPf"
};