exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Philosopher&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Rubik+Mono+One&display=swap);", ""]);

// module
exports.push([module.id, "body {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: 'Quicksand', sans-serif; }\n\nhtml {\n  overflow-y: auto;\n  overflow-x: hidden; }\n\nul, li {\n  list-style: none;\n  padding: 0px;\n  margin: 0px; }\n\n#root {\n  width: 100%;\n  margin-right: calc(100% - 100vw); }\n\na {\n  -webkit-tap-highlight-color: transparent;\n  transition: all 200ms linear;\n  color: #009eb4; }\n  a:hover {\n    color: #af0d1a; }\n\n/* ============ z-index */\n.z_avatar {\n  z-index: 1020; }\n\n.z_nav {\n  z-index: 1010; }\n\n.z_board {\n  z-index: 1000; }\n\n.z_overlay {\n  z-index: 990; }\n\n.z_title {\n  z-index: 910; }\n\n.z_title_board {\n  z-index: 900; }\n\n.z_main {\n  z-index: 100; }\n", ""]);

// exports
